<!--页面代码从这里开始拷贝-->
<template>
  <div>
    <table-compnent :loading="loading" :showSelection="false" :records="tableData" @searchHandler="doSearch"
      @resetHandler="reset" :showDelete="false" :showAdd="false">
      <template slot="search">
        <el-form-item label="车牌号：">
          <el-input v-model.trim="search.carNum" placeholder="请输入车牌号..."></el-input>
        </el-form-item>
        <el-form-item label="所属公司">
          <el-input v-model.trim="search.companyName" placeholder="请输入公司..."></el-input>
        </el-form-item>
        <el-form-item label="所属车队">
          <el-input v-model.trim="search.motorcadeName" placeholder="请输入车队..."></el-input>
        </el-form-item>
      </template>
      <template v-slot:content>
        <el-table-column prop="carNum" label="车牌号">
          <template slot-scope="scope">
            <el-button type="text" @click="toDetail(scope.row.id)">{{ scope.row.carNum }}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="companyName" label="所属公司" />
        <el-table-column prop="motorcadeName" label="所属车队" />
        <el-table-column prop="type" label="车辆类型" />
        <el-table-column prop="brand" label="品牌型号" />
        <el-table-column prop="maxNum" label="最大载客数" />
        <el-table-column prop="registerTime" label="注册日期">
          <template scope="s">
            {{ dateFormatType(s.row.registerTime) }}
          </template>
        </el-table-column>
      </template>
    </table-compnent>
  </div>
</template>
  
  <script>
import listPage from "../../base/listPage.vue";
import TripCarApi from "@/api/TripCarApi";
export default {
  name: "TripCarList",
  extends: listPage,
  data() {
    return {
      tableData: {},
      search: {
        carNum: "",
        companyName: "",
        belong: "",
      },
      page: {},
    };
  },
  methods: {
    reset() {
      this.search = this.$options.data().search;
      this.doSearch({ pageSize: 20, pageNum: 1 });
    },
    doSearch(params) {
      this.loading = true;
      this.page = params;
      this.search.pageSize = this.page.pageSize;
      this.search.pageNum = this.page.pageNum;
      TripCarApi.search(this.search).then((resp) => {
        if (resp.code === '200') {
          this.tableData = resp.data;
          this.loading = false;
        }
      });
    },
    toDetail(id) {
      this.navTo(`/tripCarDetail/${id}`);
    },
    deleteById(obj) {
      TripCarApi.deleteTripCar(obj.id).then((resp) => {
        if (resp.code === '200') {
          this.doSearch(this.page);
        } else {
          this.$errorMsg(resp.msg);
        }
      });
    },
    batchDelete(params) {
      TripCarApi.batchDeleteTripCar(params).then((resp) => {
        if (resp.code === '200') {
          this.$successMsg(resp.msg);
          this.doSearch(this.page);
        } else {
          this.$errorMsg(resp.msg);
        }
      });
    }
  },
};
</script>
  <!--页面代码从这里结束拷贝-->